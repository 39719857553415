<template>
    <div id="getnodes">
        <div v-if="!isDataLoaded">
            <TheSpinner />
        </div>
        <div v-else>
            <v-row justify="center">
                <v-col md="12">
                    <div id="dialog"></div>
                    <v-card elevation="5" md="12">
                        <v-card-title>
                            <v-row>
                                <v-col color="accent" md="5">
                                    <strong>{{ $t('menu.buttons.nodes') }}</strong>
                                </v-col>
                                <v-col md="3">
                                    <v-text-field
                                        ref="nodeSearch"
                                        v-model="search"
                                        append-icon="mdi-magnify"
                                        class="mt-2"
                                        clearable
                                        dense
                                        hide-details
                                        :label="$t('main.search')"
                                        single-line
                                        solo
                                    />
                                </v-col>
                            </v-row>
                        </v-card-title>
                        <div>
                            <node-dialog
                                z-index="1000"
                                :dialog-item="editedItem"
                                :dialog.sync="dialog"
                                @has-changed="dataHasChanged"
                                v-if="editedItem"/>
                            <v-data-table
                                :headers="headers"
                                :item-key="nodesList.id"
                                :items="nodesList"
                                :search="search"
                                class="elevation-5"
                                @click:row="handleClickRow"
                                :footer-props="{
                                    itemsPerPageText: $t('main.rowsPerPage'),
                                    pageText: '{0} - {1} ' + $t('main.of') + ' {2}'
                                }"
                                :no-data-text="$t('main.noDataFound')"
                                :no-results-text="$t('main.noSearchResults')"
                            >
                                <template #item.id="{ item }">
                                    <div class="noCursor">
                                        {{ item.id }}
                                    </div>
                                </template>
                                <template #item.user_field="{ item }">
                                    <div class="noCursor">
                                        {{ item.user_field }}
                                    </div>
                                </template>
                                <template #item.hardware.hardware_id="{ item }">
                                    <div class="noCursor">
                                        {{ item.hardware.hardware_id}}
                                    </div>
                                </template>
                                <template #item.serialNumbers="{ item }" class="noCursor">
                                    <div class="noCursor">
                                        <template v-if="item.serialNumbers.length <= 40">
                                            {{ item.serialNumbers }}
                                        </template>
                                        <v-tooltip bottom v-else>
                                            <template v-slot:activator="{on,attrs}">
                                                <div v-bind="attrs" v-on="on" @click="copyTextToClipboard(item.serialNumbers)">{{ fixSerialNumbers(item.serialNumbers) }}</div>
                                            </template>
                                            <span>{{ item.serialNumbers }}</span>
                                        </v-tooltip>
                                    </div>
                                </template>
                                <template #item.zones="{ item }">
                                    <div v-if="item.zones.length > 0">
                                        <div v-for="zone of item.zones" :key="zone.id" class="scheduleLink">
                                            <router-link class="scheduleLink" :to="{name: 'Map', query: { name: zone.name, city: zone.city, id: zone.id }}">
                                                {{ zone.name }} ({{zone.city}})
                                            </router-link>
                                        </div>
                                    </div>
                                    <div v-else>
                                        -
                                    </div>
                                </template>
                                <template #item.connection.last_seen="{ item }">
                                    <div  class="noCursor">
                                        {{ prettyFormatDate(item.connection.last_seen) }}
                                    </div>
                                </template>
                                <template #item.connection.online="{ item }">
                                    <div class="noCursor">
                                        <v-icon :class="{ online: !item.connection.online, offline: item.connection.online }">{{ item.connection.online ? 'mdi-check-circle' : 'mdi-cancel' }}</v-icon>
                                    </div>
                                </template>
                                <template #item.map="{ item }">
                                    <div v-if="item.location">
                                        <v-tooltip bottom>
                                            <template #activator="{ on,attrs }">
                                            <span
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <router-link :to="{ name: 'Map', params: { centerOnCoords: item.location }}"
                                                >
                                                    <v-icon v-if="item.location.type === 'gps'">mdi-map-marker</v-icon>
                                                    <v-icon v-else>mdi-map-marker-question-outline</v-icon>
                                                </router-link>
                                            </span>
                                            </template>
                                            <span>({{ item.location.latitude }},{{ item.location.longitude }})</span>
                                        </v-tooltip>
                                    </div>
                                    <div v-else class="noCursor">
                                        <v-icon>mdi-minus</v-icon>
                                    </div>
                                </template>
                                <template #item.schedule.id="{ item }">
                                    <div v-if="item.schedule.id">
                                        <router-link class="scheduleLink" :to="{name: 'schedNew', params: { crudType: 'edit'}, query: {
                                            schedule_id: item.schedule.id,
                                            schedule_version: item.schedule.version,
                                            io_id: 0,
                                            profile_id: 0
                                        }}">
                                        {{ item.schedule.id }}: {{ item.schedule.name }}
                                        </router-link>
                                    </div>
                                    <div v-else>
                                        <v-icon class>mdi-minus</v-icon>
                                    </div>
                                </template>
                                <template #item.actions="{ item }">
                                    <v-icon
                                        color="blue"
                                        small
                                        class="mr-2"
                                        @click.stop="editItem(item)"
                                    >
                                        mdi-pencil
                                    </v-icon>
                                </template>
                            </v-data-table>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {cloneDeep as _cloneDeep} from "lodash";
import {getNodeIds, setUserfield} from "@/services/apiHelpers";
import dayjs from "dayjs";
import NodeDialog from "./NodeDialog.vue";
import TheSpinner from "@/components/Standard/TheSpinner.vue";

export default {
    name: "Nodes",
    components: {
        TheSpinner,
        NodeDialog
    },
    created() {
        this.populateList();
    },
    data() {
        return {
            search: '',
            dataLoaded: false,
            nodesList: [],
            selectedOrganisation: '',
            max25chars: v => v.length <= 25 || 'Input too long!', // TODO fix for translation
            dialog: false,
            editedItem: null,
            dataChanged: false,
        };
    },
    computed: {
        ...mapGetters({ 'authStatus': 'auth/authStatus', 'stateScheduleList': 'ctz/StateScheduleList', 'activeOrganisation': 'auth/StateActiveOrganisation',
         'onlineNodes': 'ctz/StateOnlineList', 'stateNodeList': 'ctz/StateNodeList', 'stateNodeMeta': 'ctz/StateNodeMeta'}),
        headers() {
            return [
                { id: 1, text: this.$t('menu.buttons.node'), value: 'id'},
                { id: 8, text: 'User Field', value: 'user_field', class: 'noCursor'},
                { id: 2, text: 'Hardware ID', value: 'hardware.hardware_id', class: 'noCursor'},
                { id: 7, text: 'Serial Numbers', value: 'serialNumbers', class: 'noCursor'},
                { id: 10, text: 'Zones', value: 'zones', class: 'noCursor'},
                { id: 3, text: this.$t('main.lastSeen'), value: 'connection.last_seen', class: 'noCursor'},
                { id: 4, text: 'Online', value: 'connection.online', class: 'noCursor'},
                { id: 5, text: this.$t('main.viewOnMap'), value: 'map' },
                { id: 6, text: this.$t('menu.buttons.schedule'), value: 'schedule.id'},
                { id: 9, text: 'actions', value: 'actions', sortable: false}
            ];
        },
        activeOrg(){
            return this.activeOrganisation;
        },
        isDataLoaded(){
            return this.dataLoaded;
        }
    },
    methods: {
        gotoMap(item){
            this.$router.push({name: 'Map', query: { name: item.name, city: item.city, id: item.id }});
        },
        copyTextToClipboard(item){
            navigator.clipboard.writeText(item);
            alert('Serial Numbers copied to Clipboard');
        },
        fixSerialNumbers(data){
            return data.substring(0, 37) + "...";
        },
        prettyFormatDate(data){
            const dateFormat = this.$i18n.locale === 'en' ? 'YYYY-MM-DD @ HH:mm' : 'DD-MM-YYYY @ HH:mm';
            return dayjs(`${data}`, "YYYY-MM-DD[T]HH:mm:ss[Z]").format(dateFormat);
        },
        shortListString(longList){
            let list = [];
            longList.nodes.forEach((element) => list.push(element.substr(15, element.length - 1)));
            return list.join(",");
        },
        isEmpty(obj) {
            return Object.keys(obj).length === 0;
        },
        myMerge(myOnlineNodes, myMetaNodes, myNodeSchedules) {
            let result = [];
            myMetaNodes.forEach((elem) => {
                // an extra field 'serialNumbers' is added to the objects. to show the serial numbers of each module.
                let serials = "";
                elem['hardware'].modules.forEach((module) => {
                    serials += module.serial_number ? module.serial_number + "," : "";
                });
                result.push({
                    ...elem,
                    serialNumbers: serials.substring(0, serials.length - 1),
                    connection: myOnlineNodes.find((el) => el.id === elem.id).connection,
                    schedule: myNodeSchedules.find((ele) => ele.id === elem.id).schedule
                })
            })
            return result;
        },
        async populateList() {
            // check which organisation is the active Organisation
            const org = this.activeOrganisation;
            let NodeList = [];
            if (org){
                const tmpNodelist = await getNodeIds(org, 1);
                NodeList.splice(0,0, tmpNodelist);
            }

            if (NodeList.length > 0) {
                await Promise.all([
                    this.$store.dispatch('ctz/getNodesOnline', NodeList),
                    this.$store.dispatch('ctz/getMetaNodes', NodeList),
                    this.$store.dispatch('ctz/getSchedulesNodes', NodeList)
                ]);

                const myOnlineNodes = this.onlineNodes;
                const myMetaNodes = this.stateNodeMeta;
                const myScheduleNodes = this.stateScheduleList;
                const tempList = _cloneDeep(this.myMerge(myOnlineNodes, myMetaNodes, myScheduleNodes));
                const oldLength = this.nodesList.length;
                this.nodesList.splice(0, oldLength, ...tempList);


            } else {
                // No nodes to display. Don't load the other lists
            }
            this.dataLoaded = true;
        },
        editItem(item){
            this.editedItem = {...item};
            this.dialog = true;
            this.populateList();
        },
        dataHasChanged(s){
            if (s.changed){
                this.dialog = false;
                this.populateList();
                this.editedItem = null;
            } else if (s.close){
                this.dialog = false;
                this.editedItem = null;
            }
        },
        handleClickRow(row){
            this.$router.push({name: 'Dashboard', params: { ctzid: row.id }});
        }
    },
    watch:{
        activeOrg(newV, oldV) {
            if(newV !== oldV) {
                this.populateList();
            }
        }
    }
}
</script>

<style scoped>
.online {
    color: red;
}
.offline {
    color: green;
}
.scheduleLink {
    color: #515151;
    text-decoration: none;
}
.noCursor {
    cursor: default;
}
</style>
