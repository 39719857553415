<template>
    <div>
        <v-card>
            <v-card-text class="text-center title-text">
                <router-link class="nodeLink" :to="{name: 'Dashboard', params: { ctzid: node['id'] }}">{{ node['id'] }}</router-link></v-card-text>
            <div class="pop1">
                <table>
                    <tbody>
                        <tr>
                            <td>{{ node['location'] ? node['location'].type : 'No GPS' }}</td>
                            <td>{{ formatGPS(node['location']) }}</td>
                        </tr>
                        <tr>
                            <td>{{ $t('main.online') }}</td>
                            <td :class="{'online-class-green': node['connection'].online, 'online-class-red': !node['connection'].online}">
                                {{ node['connection'].online ? $t("main.YES") : $t("main.NO") }}
                            </td>
                        </tr>
                        <tr>
                            <td>{{ $t('main.lastSeen') }} : </td>
                            <td>{{ formatDate(node['connection'].last_seen) }}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>({{ writtenDate(node['connection'].last_seen) }})</td>
                        </tr>
                        <tr>
                            <td>User field : </td>
                            <td>{{ node['user_field'] ? node['user_field'] : '' }}</td>
                        </tr>
                        <tr class="pop2" v-if="!node['connection'].online">
                            <td colspan="2">{{ $t('main.nodeOffline') }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </v-card>
    </div>
</template>
<script>
import {dateTimeStrConv} from "@/services/dateConverter";
const dayjs = require('dayjs');
const relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

export default {
    name: "NodeAttributes",
    props: {
        node: {
            type: Object,
            required: true,
            default: null
        }
    },
    methods: {
        formatDate(date){
            let dateStr = "NO DATE";
            const local = this.$i18n.locale;
            if (date) {
                const date2 = dayjs(date).format('YYYY-MM-DDTHH:mm:ss').toString();
                dateStr = dateTimeStrConv(date2, local);
            }
            return dateStr;
        },
        formatGPS(gpsObj) {
            return `[ ${gpsObj.latitude.toFixed(5)} , ${gpsObj.longitude.toFixed(5)} ]`;
        },
        writtenDate(date) {
            const written = dayjs(dayjs(date)).fromNow();
            return written;
        }
    }
}
</script>
<style scoped>
.pop1 {
    padding: 2px;
    margin: 2px 5px 2px 2px;
    width: 100%;
}
.pop2 {
    color: red;
    font-weight: bold;
    font-size: 1rem;
}
.title-text {
    height: 45px;
    width: 250px !important;
    font-size: 1rem;
    font-weight: bold;
}
table, td, tr {
    border-bottom: 1px solid gray;
    padding: 5px 5px 5px 5px;
    border-radius: 5px;
}
table {
    width: 100%;
}
.online-class-red {
    color: red;
    font-weight: bold;
}
.online-class-green {
    color: green;
    font-weight: bold;
}
.nodeLink {
    color: #515151;
}
</style>
