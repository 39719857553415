<template>
    <div class="d-flex flex-column" v-if="dataloaded" id="mapContainer">
        <zone-dialog :toggle="zoneDialog" dialog-type="nothing" :dialog-poly="dialogPoly" @data-changed="populateZones" v-if="zoneDialog"/>
        <div class="d-flex flex-row">
            <FiltersBar @activate-filter="activateFilter" @deactivate-filter="deactivateFilter"/>
            <l-map
                    ref="map"
                    :center="center"
                    :zoom="zoom"
                    style="z-index: 0;height: 82vh;"
                    :style="{cursor: areZonesLoading ? 'wait' : ''}"
                    @update:zoom="zoomUpdated"
                    @update:center="centerUpdated"
                    @update:bounds="boundsUpdated"
                    @draw:created="updateDraw"
                    @ready="handleMapReady"
            >
                <l-tile-layer :attribution="attribution" :url="url"/>
                <l-control
                        position="topleft"
                ></l-control>
                <l-control
                        position="bottomleft"
                        class="custom-control-watermark"
                >
                    CityZenZ
                </l-control>
                <l-control :style="{'cursor': areZonesLoading ? 'wait' : 'default'}" position="topright" id="leafctrl" >
                    <v-card class="pa-4 zone-info">
                        <h3 class="mb-2">{{ $t('menu.buttons.zones') }}</h3>
                        <hr class="mb-2">
                        <div v-if="areZonesLoading">Zones are being loaded..</div>
                        <div v-else-if="zones.length === 0">{{ $t('schedule.noZonesYet') }}.</div>
                        <div
                                :class="['py-1', 'pl-2', 'example-custom-control', { 'zone-shown': zone.shown, 'zone-not-shown': !zone.shown, 'zone-no-poly': !zone.latlngs || zone.latlngs.length === 0 }]"
                                v-else v-for="zone in this.zones" :key="zone.id"
                                @click.exact="showHidePoly(zone)"
                                @click.ctrl="showHideMulti(zone)"
                        >
                            {{ zone.name }}
                        </div>
                    </v-card>
                    <v-card
                            :class="['pa-2', 'font-weight-bold', {'show-all-btn-color-green': showAllOrNone,'show-all-btn-color-red': !showAllOrNone}]"
                    >
                        <div class="d-flex flex-row example-custom-control justify-center">
                            <div
                                    :style="{cursor: areZonesLoading ? 'wait' : ''}"
                                    class="px-2 show-all-btn-color-green"
                                    v-if="showAllOrNone"
                                    @click="showAll"
                            >{{ $t('menu.buttons.showAll').toUpperCase() }}</div>
                            <div
                                    :style="{cursor: areZonesLoading ? 'wait' : ''}"
                                    class="px-2 show-all-btn-color-red"
                                    v-else
                                    @click="showNone"
                            >{{ $t('menu.buttons.showNone').toUpperCase() }}</div>
                        </div>
                    </v-card>
                </l-control>
                <l-feature-group ref="features">
                    <div v-for="poly in polys" :key="poly.id" >
                        <l-polygon :lat-lngs="poly.latlngs" :color="poly.color" :key="poly.id">
                            <l-popup class="tooltip-box">
                                <div class="d-flex flex-column">
                                    <div class="d-flex flex-row">
                                        <div class="zone-first-col">{{ $t('menu.buttons.zone') }}:</div>
                                        <div>{{ poly.name }}</div>
                                    </div>
                                    <div class="d-flex flex-row">
                                        <div class="zone-first-col">{{ $t('main.city') }}:</div>
                                        <div>{{ poly.city }}</div>
                                    </div>
                                    <div v-if="poly.nodes && poly.nodes.length > 0">
                                        <div class="d-flex flex-column align-center" style="cursor:pointer">
                                            <v-select
                                                    v-model="selectedSchedule"
                                                    style="width: 180px; cursor: pointer;"
                                                    class="pt-2"
                                                    :items="schedules.map((schedule) => schedule.name)"
                                                    label="select schedule"
                                                    flat
                                                    dense
                                                    solo
                                                    hide-selected
                                            >
                                            </v-select>
                                        </div>
                                        <div class="d-flex flex-row zone-buttons justify-space-between" v-if="selectedSchedule">
                                            <v-dialog
                                                    v-model="scheduleDialog"
                                                    width="500"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <div
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            class="px-2 tooltip-btn">{{ $t('main.assign') }}</div>
                                                </template>
                                                <v-card>
                                                    <v-card-title>
                                                        {{ $t('schedule.schedule') }} "{{ selectedSchedule }}"
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <div>
                                                            {{ $t('schedule.assignScheduleToZone2',{ scheduleName: selectedSchedule, polyName: poly.name, polyCity: poly.city}) }}
                                                        </div>
                                                        <div>
                                                            {{ $t('schedule.toNodes', {nodes: (poly.nodes.join(", "))}) }}
                                                        </div>
                                                    </v-card-text>
                                                    <v-divider ></v-divider>
                                                    <v-card-actions>
                                                        <v-spacer />
                                                        <v-btn
                                                                color="success"
                                                                text
                                                                @click="assignSchedule(poly)"
                                                        >{{ $t('main.ok') }}</v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-dialog>
                                            <div class="px-2 tooltip-btn" @click="viewSchedule(poly)">{{ $t('schedule.view') }}</div>
                                        </div>
                                    </div>
                                </div>
                            </l-popup>
                        </l-polygon>
                    </div>
                </l-feature-group>


                <v-marker-cluster :options="clusterOptions">
                    <l-marker v-for="ctz in cityzenzs"
                              :key="ctz.id"
                              :icon="dynamicIcon(ctz.connection.online === true)"
                              :lat-lng="getLatLng(convertLatLng(ctz.location, 1))"
                    >
                        <l-popup>
                            <node-attributes :node="ctz" />
                        </l-popup>
                    </l-marker>
                    <!--                &lt;!&ndash; This next marker is hardcoded to mock an extra CTZ &ndash;&gt;-->
                    <!--                <l-marker-->
                    <!--                    :icon="dynamicIcon(cityzenzs[0].connection.online === true)"-->
                    <!--                    :lat-lng="palais3"-->
                    <!--                >-->
                    <!--&lt;!&ndash;                    <l-icon class="someExtraClass">&ndash;&gt;-->
                    <!--&lt;!&ndash;                        <div>&ndash;&gt;-->
                    <!--&lt;!&ndash;                            <v-icon class="icon-background" :color="cityzenzColor(cityzenzs[0].connection.online)">mdi-lightbulb</v-icon>&ndash;&gt;-->
                    <!--&lt;!&ndash;                        </div>&ndash;&gt;-->
                    <!--&lt;!&ndash;                    </l-icon>&ndash;&gt;-->
                    <!--                    <l-popup>-->
                    <!--                        <node-attributes :node="cityzenzs[0]" />-->
                    <!--                    </l-popup>-->
                    <!--                </l-marker>-->
                </v-marker-cluster>
            </l-map>
        </div>
        <ActionBar :filter-actions="filterActions" v-if="isSuperAdmin" :showBar="showActionBar"/>
    </div>
</template>

<script>
import L, {Control, CRS, FeatureGroup} from 'leaflet';
import {LMap, LTileLayer, LMarker, LPolygon, LControl, LPopup, LFeatureGroup } from 'vue2-leaflet';
import 'leaflet-draw';
/* eslint-disable-next-line */
import LDrawToolbar from 'vue2-leaflet-draw-toolbar';
import Vue2LeafletMarkercluster from "vue2-leaflet-markercluster";
import { mapActions, mapGetters } from 'vuex';
import "leaflet/dist/leaflet.css";
import {cloneDeep as _cloneDeep, max as _max, min as _min} from 'lodash';
import {assignScheduleToZone, getScheduleIds, getZones} from "@/services/apiHelpers.js";
import ZoneDialog from '@/components/ZoneDialog.vue';
import NodeAttributes from "@/components/Standard/NodeAttributes.vue";
import FiltersBar from "@/components/FiltersBar.vue";
import ActionBar from "@/components/ActionBar.vue";

/*
*       The use of POLYGON's (or MultiLinePolygons that we use) is described in
*       - https://geojson.org/geojson-spec.html#id7
*       - https://stackoverflow.com/questions/43645172/geojson-multipolygon-with-multiple-holes
*
*/

export default {
    name: "Map",
    components: {
        ActionBar,
        FiltersBar,
        ZoneDialog,
        LPolygon,
        LControl,
        LPopup,
        LFeatureGroup,
        LMap,
        LTileLayer,
        LMarker,
        "v-marker-cluster": Vue2LeafletMarkercluster,
        NodeAttributes,
    },
    props: {
        centerOnCoords: {
            type: Object,
            required: false
        }
    },
    mounted() {
        this.init();
    },
    computed: {
        ...mapGetters({'stateNodeMeta': 'ctz/StateNodeMeta', 'stateNodeOnline': 'ctz/StateOnlineList',
            'selectedOrganisation': 'auth/StateActiveOrganisation', 'stateNodeList': 'ctz/StateNodeList',
            'isSuperAdmin': 'auth/StateIsSuperAdmin'}),
        publishMode(){
            let mode = 0;
            /*
                0 = all
                1 = published
                2 = active
                3 = published & active
             */
            if (this.scheduleType.published) {
                if (this.scheduleType.active) {
                    mode = 3;
                } else mode = 1;
            } else {
                if (this.scheduleType.active) {
                    mode = 2;
                } else mode = 0;
            }
            return mode;
        },
        areZonesLoading() {
            return this.zones.length > 0 && !this.zonesLoaded;
        },
        loadingCursor() {
            return this.areZonesLoading ? 'wait' : '';
        }
    },
    data () {
        return {
            zoneDialog: false,
            scheduleDialog: false,
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attribution: '&copy; OpenStreetMap',
            zoom: 12,
            center: L.latLng(50.9246543,5.2311457), // updated to center on hasselt(B)
            bounds: null,
            crs: CRS.Simple,
            drawnItems: null,
            showParagraph: false,
            cityzenzs: [],
            loading: true,
            // TODO serve these marks via .png (we want cache busting)
            greenMarker: L.icon({
                iconUrl: 'marker-icon-green.png',

            }),
            redMarker: L.icon({
                iconUrl: 'marker-icon-red.png',
            }),
            dataloaded: false,
            clusterOptions: {
                disableClusteringAtZoom: 16,
                chunkedLoading: true,
                maxClusterRadius: 120,
                iconCreateFunction: function(cluster){
                    const markers = cluster.getAllChildMarkers();
                    const errors = markers.map((marker) => marker['options']['icon']['options']['iconUrl'].includes('red')).filter((el) => el).length;
                    return L.divIcon({
                        html: `<div><span>${markers.length}</span></div>`,
                        className: errors > 0 ? 'marker-cluster marker-cluster-large' : 'marker-cluster marker-cluster-small',
                        iconSize: L.point(40, 40)
                    });
                }
            },
            mapObject: null,
            polys: [],
            newItem: {
                name: '',
                city: '',
                latlngs: [],
            },
            zones: [],
            bounding_box: null,
            zonesLoaded: false,
            showAllOrNone: true,
            ctrlPressed: false,
            dialogPoly: null,
            mapLoaded: false,
            schedules: null,
            schedulesList: [],
            selectedSchedule: '',
            scheduleType: {
                published: true,
                active: true
            },
            chosenFilters: [],
            filterActions: [],
            showActionBar: false
        }
    },
    methods: {
        ...mapActions({'getNodesList': 'ctz/getNodesList', 'getMetaNodes': 'ctz/getMetaNodes', 'getOnlineNodes': 'ctz/getNodesOnline'}),
        init() {

          this.updateToolbarLocales();

          if(this.selectedOrganisation)
            {
              this.getNodesList()
                  .then(() => {
                    const shortList = this.stateNodeList;
                    if (shortList) {
                      Promise.all([
                        this.getMetaNodes(shortList),
                        this.getOnlineNodes(shortList)
                      ]);
                      this.mergeArrays();
                      this.dataloaded = true;
                    }
                  });
              if (this.centerOnCoords) {
                this.zoomUpdated(16);
                this.centerUpdated([this.centerOnCoords.latitude, this.centerOnCoords.longitude]);
              }
            }
        },
        async populateZones(dataChanged = null) {
            // Get all the info for all the zones (shapes etc)
            try {
                // getZones with name, city and bounding_box
                const zones = await getZones(this.selectedOrganisation)
                const oldLength = this.zones.length;
                if (zones['bounding_box']) this.bounding_box = zones['bounding_box'];
                this.zones.splice(0, oldLength, ...zones['zones']);
                await this.fetchZonesNew(zones, dataChanged);
            } catch(error) {
                console.log("There was an error in populateZonesNEW : ", error);
            } finally {
                // calculate bounding box
                this.zoneDialog = false;
            }
        },
        async fetchZonesNew(zones, dataChanged){
            const zoneString = zones['zones'].map((el) => el.id).join(',');
            let currZone
            const zonesWithNodesShape = getZones(this.selectedOrganisation, zoneString, 3)
                .then((response) => {
                    const zones = response['zones'];
                    zones.forEach((zone) => {
                        let thisZoneId = this.zones.findIndex((el) => el.id === zone.id);
                        this.zones[thisZoneId].nodes = zone.nodes;
                        this.zones[thisZoneId].latlngs = zone.shape ? this.convertLngLat(zone.shape) : [];
                    })
                })
                .then(() => {
                    if(this.$route.query.id) {
                        const zoneId = parseInt(this.$route.query.id);
                        currZone = this.zones[this.zones.findIndex((el) => el.id === zoneId)];
                    } else if (dataChanged && dataChanged.isChanged) {
                        const idx = this.zones.findIndex((el) => el.name === dataChanged.newZoneName && el.city === dataChanged.newZoneCity);
                        currZone = this.zones[idx];
                    } else return;
                    this.showHidePoly(currZone);
                })
                .then(async () => {
                    const scheduleMode = this.publishMode;
                    const scheds = await getScheduleIds(this.selectedOrganisation, scheduleMode)
                    this.schedules = _cloneDeep(scheds);
                })
                .finally(() => {
                    if (!this.centerOnCoords) {
                        if (this.zones.length !== 0){
                            // if there ARE zones, center on all zones and zoom-to-fit
                            let newCenter
                            if(currZone) {
                                newCenter = this.calculateCenterOfPoly(currZone.latlngs);
                            } else {
                                // get center from bounding box
                                const corner1 = L.latLng(this.bounding_box[1], this.bounding_box[0]);
                                const corner2 = L.latLng(this.bounding_box[3], this.bounding_box[2]);
                                newCenter = this.calculateCenterOfPoly([corner1, corner2]);
                            }
                            this.centerUpdated(newCenter.center);
                            this.mapObject.fitBounds(newCenter.bounds);
                        } else {
                            // if there are no zones yet. Center on first Node in list
                            const node = this.cityzenzs ? this.cityzenzs[0].location : null;
                            const latlngNode = node ? {lat: node.latitude, lng: node.longitude} : [51.9843728,4.9755739];
                            this.centerUpdated(latlngNode);
                        }
                    }
                    this.zonesLoaded = true;
                    this.mapLoaded = true;
                })
                .catch((error) => console.log("There was an error in fetchZones() : " + error));
        },
        convertLngLat(lnglatObj){
            // lnglatObj is actually an array with two (float) values. The longitude first and the latitude second.
            // This must be converted to an object that leaflet understands..
            // obj = {
            //          lat: xx
            //          lng: xx
            //       }
            // returns an array with latLngObjects

            let result = [];

            const tmpObj = lnglatObj['coordinates'][0][0];

            if (tmpObj.length === 0) return result;
            else {
                tmpObj.forEach((el) => {
                    result.push({
                        lat: el[1],
                        lng: el[0]
                    })
                })
                return result;
            }
        },
        showAll(){
            this.polys = [];
            this.zones.forEach((el) => {
                if (el.latlngs) {
                    el.shown = true;
                    this.polys.push(el);
                }
            });
            this.showAllOrNone = !this.showAllOrNone;
            if (!this.showAllOrNone) {
                const newCenter = this.calculateGlobalCenter();
                this.centerUpdated(newCenter.center);
                this.mapObject.fitBounds(newCenter.bounds);
            }

        },
        showNone(){
            this.polys = [];
            this.zones.forEach((el) => el.shown = false);
            this.showAllOrNone = !this.showAllOrNone;
        },
        zoomUpdated (zoom) {
            this.zoom = zoom;
        },
        centerUpdated (center2) {
            if (center2 instanceof Array){
                this.center = L.latLng(center2[0], center2[1]);
            } else {
                this.center = center2;
            }
        },
        boundsUpdated(bounds){
            this.bounds = bounds;
        },
        isEmpty(str) {
            return (!str || str.length === 0);
        },
        getLatLng(latlngstr) {
            if (!this.isEmpty(latlngstr)){
                if(latlngstr.search(',') > -1){
                    const tmp = latlngstr.split(",");
                    return [parseFloat(tmp[0]), parseFloat(tmp[1])];
                }
            } else {
                return [0.00, 0.00];
            }
        },
        dynamicIcon(is_online) {
            if (is_online) {
                return this.greenMarker;
            } else {
                return this.redMarker;
            }
        },
        /**
         * @function convertLatLng
         * @description return a stringified version of the Latitude and Longitude Object.
         *
         * @param {Object} thisNode The Node object. { latitude: {Float}, longitude: {Float}, type: {String} }
         * @param {Number} mode mode for returning. 1 for long floats, 2 for short floats (better to display). Default is 1.
         * @returns {string} String representation of the latlng-object. "{Float},{Float}"
         */
        convertLatLng(thisNode, mode=1){
            let lat, lng, type;
            if (!thisNode) return "";
            else if (mode === 1) {
                lat = thisNode['latitude'];
                lng = thisNode['longitude'];
                // 'gps' =  live , 'fixed' = fixed
                type = thisNode['type'];
            } else if (mode === 2) {
                lat = parseFloat(thisNode['latitude'].toFixed(4));
                lng = parseFloat(thisNode['longitude'].toFixed(4));
            }
            return `${lat},${lng}`;
        },
        mergeArrays(){
            let array = [];
            this.stateNodeMeta.forEach((el) => {
                const online = this.stateNodeOnline.find((elem) => elem.id === el.id);
                array.push({
                    ...el,
                    connection: online.connection
                })
            });
            this.cityzenzs = array;
        },
        updateDraw(event) {
            this.zoneDialog = true;
            const poly = {
                id: -1,
                name: `test 42`,
                latlngs: [],
                color: '#3388ff'
            };
            poly.latlngs = event.layer._latlngs;
            this.polys.push(poly);
            this.dialogPoly = _cloneDeep(poly.latlngs);
        },
        handleMapReady() {
          this.init();
          this.mapObject = this.$refs.map.mapObject;

          this.drawnItems = new FeatureGroup();

          const options = {
            edit: false,
            draw: {
              polygon: {
                title: 'Draaaaw',
                allowIntersection: false, // Restricts shapes to simple polygons
                drawError: {
                  color: '#e1e100', // Color the shape will turn when intersects
                  message: '<strong>Polygon draw does NOT allow intersections!<strong> '
                      + `(allowIntersection: false)`,
                  // Message that will show when intersect
                },
                shapeOptions: {
                  weight: 2, // thickness of the line
                  color: '#0000FF' // color of the line
                },
              },
              marker: false,
              circle: false,
              polyline: false,
              rectangle: false,
              circlemarker: false,
            }
          };

          const drawControl = new Control.Draw(options);
          this.mapObject.addControl(drawControl);

          this.populateZones();
        },
        calculateCenterOfPoly(polyLatLngs){
            const lats = polyLatLngs.map((el) => el.lat);
            const lngs = polyLatLngs.map((el) => el.lng);
            let center = {
                min: {
                    lng: Math.min(...lngs),
                    lat: Math.min(...lats)
                },
                max: {
                    lng: Math.max(...lngs),
                    lat: Math.max(...lats)
                }
            }
            // Put the Center and the Bounds in an Object for later use.
            center['center'] = {lat: (center.min.lat + center.max.lat) / 2, lng: (center.min.lng + center.max.lng) / 2};
            center['bounds'] = L.latLngBounds(L.latLng(center.min.lat, center.min.lng), L.latLng(center.max.lat, center.max.lng));
            return center;
        },
        /**
         * @function showHidePoly
         * @description A function that show or hides a poly on the map, as well as shows or hides the
         *                  box in the list. The list should only show ONE poly at a time. So clicking
         *                  another one removes the current one. to select more than one: Press the CTRL
         *                  while cliking on one or more polys.
         *
         * @param {Object} item polyObject given from the Map
         * @returns {void}
         */
        async showHidePoly(item) {
            const poly = {
                id: item.id,
                name: item.name,
                city: item.city ? item.city : '',
                latlngs: [],
                color: '#3388ff',
            };
            const zoneInfo = await getZones(this.selectedOrganisation, item.id);
            const zoneIndex = this.zones.findIndex((pol) => pol.id === poly.id);
            const currentPolyIds = this.polys.map((el) => el.id);
            if (this.zones[zoneIndex].latlngs) {
                if (currentPolyIds.includes(poly.id)) {
                    // if this zone was already selected, toggle it
                    const polyIndex = this.polys.findIndex((el) => el.id === poly.id)
                    this.polys.splice(polyIndex, 1);
                    this.zones[zoneIndex]['shown'] = false;
                } else {
                    // else add it to empty list of poly's
                    this.zones.forEach((el) => el.shown = false)
                    this.polys = [];
                    // add poly to array
                    poly.latlngs = this.zones[zoneIndex].latlngs;
                    if (this.zones[zoneIndex].nodes){
                        poly.nodes = this.zones[zoneIndex].nodes;
                    }
                    this.polys.push(poly);
                    this.zones[zoneIndex]['shown'] = true;
                    // and center view on it
                    const center = this.calculateCenterOfPoly(poly.latlngs)
                    this.centerUpdated(center.center);
                    this.mapObject.fitBounds(center.bounds);
                    if (this.$route.query.lat !== `${center.center.lat}` && this.$route.query.lng !== `${center.center.lng}`)
                    await this.$router.push({name: 'Map', query: {lat: `${center.center.lat}`,lng: `${center.center.lng}`,zoom: `${this.mapObject._zoom}`}, replace: true});
                }

            }
        },
        showHideMulti(item) {
            const poly = {
                id: item.id,
                name: item.name,
                city: item.city,
                latlngs: item.latlngs ? item.latlngs : [],
                color: '#3388ff',
            };
            const zoneIndex = this.zones.findIndex((el) => el.id === poly.id);
            if (this.zones[zoneIndex].shown) {
                this.zones[zoneIndex].shown = false;
                const polysIndex = this.polys.findIndex((el) => el.id === poly.id);
                this.polys.splice(polysIndex, 1);
            } else {
                poly.latlngs = this.zones[zoneIndex].latlngs;
                this.zones[zoneIndex].shown = true;
                this.polys.push(poly);
            }
        },
        assignSchedule(poly) {
            this.mapObject.closePopup();
            const schedule = this.schedules.find((sched) => sched.name === this.selectedSchedule);
            assignScheduleToZone(this.selectedOrganisation, poly.id, schedule.uid);
            this.scheduleDialog = false;
        },
        viewSchedule(poly){
            this.mapObject.closePopup();
            const schedule = this.schedules.find((sched) => sched.name === this.selectedSchedule);
            // first save what we were doing for when the user wants to go back
            this.$router.push({name: 'Map', query: {id: poly.id, schedule_id: schedule.id, schedule_version: schedule.version}, replace: true});
            // then go to the schedule
            this.$router.push({name: 'schedInfo', query: {zone_id: poly.id, schedule_id: schedule.id, schedule_version: schedule.version}})
        },
        calculateGlobalCenter() {
            let zonesMap3 = {
                minLat: 1000,
                maxLat: -1000,
                minLng: 1000,
                maxLng: -1000
            };
            const zonesMap = this.zones.map((el) => ({
                lat: el.latlngs.map((latlngs1) => latlngs1.lat),
                lng: el.latlngs.map((latlngs2) => latlngs2.lng)
            }));

            const zonesMap2 = zonesMap.map((zoneArray) => ({
                minLat: _min(zoneArray.lat),
                maxLat: _max(zoneArray.lat),
                minLng: _min(zoneArray.lng),
                maxLng: _max(zoneArray.lng),
            }))

            zonesMap2.forEach((array) => {
                if (array.minLat < zonesMap3.minLat) {
                    zonesMap3.minLat = array.minLat;
                }
                if (array.maxLat > zonesMap3.maxLat){
                    zonesMap3.maxLat = array.maxLat;
                }
                if (array.minLng < zonesMap3.minLng) {
                    zonesMap3.minLng = array.minLng;
                }
                if (array.maxLng > zonesMap3.maxLng){
                    zonesMap3.maxLng = array.maxLng;
                }
            });
            let center = {};
            center['center'] = {lat: (zonesMap3.minLat + zonesMap3.maxLat) / 2, lng: (zonesMap3.minLng + zonesMap3.maxLng) / 2};
            center['bounds'] = L.latLngBounds(L.latLng(zonesMap3.minLat, zonesMap3.minLng), L.latLng(zonesMap3.maxLat, zonesMap3.maxLng));

            return center;
        },
        updateToolbarLocales(){
            L.drawLocal.draw.toolbar = {
              actions: {
                title: this.$t('tooltip.map.actionsTitle'),
                text: this.$t('tooltip.map.actionsText')
              },
              finish: {
                title: this.$t('tooltip.map.finishTitle'),
                text: this.$t('tooltip.map.finishText'),
              },
              undo: {
                title: this.$t('tooltip.map.undoTitle'),
                text: this.$t('tooltip.map.undoText'),
              },
              buttons: {
                polyline: this.$t('tooltip.map.buttonsPolyline'),
                polygon: this.$t('tooltip.map.buttonsPolygon'),
                rectangle: this.$t('tooltip.map.buttonsRectangle'),
                circle: this.$t('tooltip.map.buttonsCircle'),
                marker: this.$t('tooltip.map.buttonsMarker'),
                circlemarker:  this.$t('tooltip.map.buttonsCirclemarker'),
              }
            };

            L.drawLocal.draw.handlers.polygon.tooltip = {
              start: this.$t('tooltip.map.polygonStart'),
              cont: this.$t('tooltip.map.polygonCont'),
              end: this.$t('tooltip.map.polygonEnd')
            };
        },
        activateFilter(filter, actions){
            console.log(`Activate ${filter}`);
            const oldLength = this.chosenFilters.length;
            this.chosenFilters.splice(0, oldLength, filter);
            const oldActionsLength = this.filterActions.length;
            this.filterActions.splice(0, oldActionsLength, ...actions);
            this.filter(filter);
        },
        deactivateFilter(filter){
            console.log(`Deactivate ${filter}`)
            const index = this.chosenFilters.indexOf(filter);
            if (index > -1) {
                this.chosenFilters.splice(index, 1);
                const oldLength = this.filterActions.length;
                this.filterActions.splice(0, oldLength, ...[])
            }
        },
        filter(filter){
            if(this.cityzenzs.length === 0) return;
            switch(filter){
                case 'All exceptions':
                    break;
                case 'Offline':
                    console.log(`Filtering on ${filter}`);
                    // eslint-disable-next-line no-case-declarations
                    const ctz = this.cityzenzs.filter((el) => el.connection.online === false);
                    this.cityzenzs.splice(0, this.cityzenzs.length, ...ctz);
                    break;
                case 'Door open':
                    break;
                case 'Cable fault':
                    break;
                case 'Voltage error':
                    break;
                case 'Override mode':
                    break;
                case 'ALL/NO nodes':
                    break;
                case 'ID':
                    break;
                case 'User field':
                    break;
                case 'Serie number':
                    break;
                case 'GPS location':
                    break;
            }
        }
    },

}
</script>
<style>
/*
   We use a global override to adjust the leaflet css to our liking.
   This is because the leaflet css is not scoped and we want to avoid
   global css pollution.
*/

#app .leaflet-container a {
  color: #000;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-polygon {
    background-position: -150px -1px;
    background-size: auto;
}

.marker-cluster-small {
    background-color: green !important;
}
.marker-cluster-small div {
    background-color: green !important;
}
.marker-cluster-medium {
    background-color: yellow !important;
}
.marker-cluster-medium div {
    background-color: yellow !important;
}

.marker-cluster-large {
    background-color: red !important;
}
.marker-cluster-large div {
    background-color: red !important;
}

</style>
<style scoped>
@import "~leaflet/dist/leaflet.css";
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

#mapContainer {
    cursor: v-bind(loadingCursor);
}
.tooltip-btn {
    border: 1px black solid;
    border-radius: 5px;
    font-size: 1.0em;
    padding: 2px 1px;
}
.example-custom-control {
    cursor: pointer;
}
.show-all-btn-color-green {
    background-color: lightgreen;
}
.show-all-btn-color-red {
    background-color: lightcoral;
}
.zone-info {
    width: 150px;
    max-height: 700px;
    overflow-y: scroll;
}
.zone-shown {
    font-weight: bold;
    padding: 0 2px;
    border: 1px blue solid;
    border-radius: 5px;
    background-color: lightsteelblue;
}
.zone-not-shown {
    padding: 0 2px;
    border: 1px gray solid;
    border-radius: 5px;
}
.zone-no-poly {
    background-color: lightpink;
    border: 1px red solid;
    border-radius: 5px;
}
.zone-first-col {
    width: 46px;
}
.zone-buttons {
    cursor: pointer;
}
.tooltip-box {
    font-size: 1.2em;
}
.checkboxText {
    font-size: 0.8em;
    padding: 1px 3px 1px 2px;
}
.custom-control-watermark {
    color: gray;
    font-weight: bold;
    background-color: white;
}
.custom-leaf {
    color: #FF0000;
}
</style>
